<template>
    <div>
        <qy-top-nav :title="(id?'编辑':'新增')+'收货地址'" />
        <van-address-edit :area-list="areaList" :address-info="addressInfo" :show-delete="!!id" show-set-default 
        :area-columns-placeholder="['请选择', '请选择', '请选择']" @save="onSave" @delete="onDelete" @change-detail="onChangeDetail" ></van-address-edit>
    </div>
</template>
<script type="text/javascript">
import { areaList } from '@vant/area-data';
import request from "@/utils/request";

export default {
    name: "addressEdit",
    data() {
        return {
            areaList:areaList,
            addressInfo:null,
            id:null,
			userId:"",
            showEdit: false,
            showDelete: false,
            searchResult:null
        };
    },
    created(){
        this.id=this.getUrlParam("id");        
        this.userId=this.getUrlParam("userId","");   
			 // console.log(this.userId)
        if(this.id){
            request.post("/api/consignee/detail/"+this.id).then(rs => {
                if(rs.success&&rs.data){
                    rs.data.tel=rs.data.phone;
                    rs.data.addressDetail=rs.data.address;
                    rs.data.isDefault=!!rs.data.isDefault;
                    this.addressInfo=rs.data;
                }
            })
        }
    },
    methods: {
        onSave(formData) {
            let data={...formData};
            data.phone=formData.tel;
            data.address=formData.addressDetail;
            data.isDefault=data.isDefault?1:0;
            if(this.id){
                data.id=this.id;
                delete data.params;
                request.post("/api/consignee/edit",data).then(rs => {
                    if(rs.success){
                       this.navigateBack();
                    }
                })
            }else{
				data.userId=this.userId;
                this.doPost("/api/consignee/add",data,rs => {
                    if(rs.success){
                        this.navigateBack();
                    }
                })
            }
        },
        onDelete() {
            this.Dialog.confirm({message:"确定要删除吗"}).then(()=>{
                request.post("/api/consignee/remove",{id:this.id}).then(rs => {
                    if(rs.success){
                        this.navigateBack();
                    }
                })
            })
        },
        onChangeDetail(){}
    }
}
</script>
<style type="text/css">
.van-switch--on{background-color:#ee0a24;}
</style>